import React from 'react';
import ServicesSidebar from './../Segments/ServicesSidebar';

class ServiceDetails7 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Fit-Outs Services</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>Interior fit-out encompasses a range of services designed to convert bare shell spaces into fully functional and aesthetically pleasing environments. Whether it's a commercial, retail, residential, or corporate space, our fit-out services are tailored to meet the unique requirements and preferences of each client.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic7.jpg')} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>We specialize in providing top-tier interior fit-out services, crafting spaces that embody functionality, aesthetics, and innovation. Our commitment to excellence and attention to detail ensures that every project we undertake is a testament to our dedication to our clients' satisfaction.</p>
                                                <p>
                                                <blockquote className="m-a0 bg-gray p-a30 lineUp">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>Space planning is the core of interior design. It is a spatial chess game, where every move is critical</p>
                                                    <div className="p-t15 text-uppercase">
                                                        {/* <strong>William Jomurray</strong>
                                                        <span>Envato Author</span> */}
                                                    </div>
                                                </blockquote>
                                                </p>
                                            </div>
                                
                                        </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>key Benefits</h2>
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> */}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li className="lineUp1">Customization</li>
                                                        <li className="lineUp2">Brand Reinforcement</li>
                                                        <li className="lineUp3">Space Optimization</li>
                                                        <li className="lineUp4">Employee Well-being</li>
                                                        <li className="lineUp5">Regulatory Compliance</li>
                                                        <li className="lineUp6">Increased Property Value</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg')} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ServiceDetails7;