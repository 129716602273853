import React from 'react';

class WhatWeDo1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b70 bg-gray what-we-do-section">
                        <div className="container">
                            <div className="section-content what-we-do-content">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="whatWedo-media-section">
                                            <div className="wt-media">
                                                <img src={require('./../../images/what-we/pic1.jpg')} alt="" />
                                            </div>
                                            <div className="whatWedo-media-content text-white">
                                                <div className="whatWedo-media-inner">
                                                    <h3>A unified team working with unwavering dedication</h3>
                                                    <p>We possess over 15 years of expertise in delivering a broad range of specialized services as outlined above</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="whatWedo-info-section">
                                            {/* TITLE START*/}
                                            <div className="left wt-small-separator-outer">
                                                <div className="wt-small-separator site-text-primary">
                                                    <div className="sep-leaf-left" />
                                                    <div>What We do</div>
                                                    <div className="sep-leaf-right" />
                                                </div>
                                                <h2>Solution for All Construction Industry</h2>
                                                <p>Discover comprehensive solutions for all your construction needs with our versatile services. From project management to sustainable building materials, advanced technologies to streamlined processes, we offer a comprehensive range of solutions tailored to the diverse requirements of the construction industry. Trust us to provide innovative and efficient solutions that drive success in every aspect of your construction projects</p>
                                            </div>
                                            {/* TITLE END*/},
                                            <div className="wt-icon-card-outer">
                                                <div className="wt-icon-card bg-white shadow">
                                                    <div className="wt-card-header"><i className="flaticon-robotic-arm site-text-primary" /><span className="title-style-2 site-text-secondry">Quality Control System</span></div>
                                                    <div className="wt-card-content"><p>We are Providing different services in this sector to wide area of world</p></div>
                                                </div>
                                                <div className="wt-icon-card bg-white shadow">
                                                    <div className="wt-card-header"><i className="flaticon-repair site-text-primary" /><span className="title-style-2 site-text-secondry">Customized Solutions</span></div>
                                                    <div className="wt-card-content"><p>We are Prous to Protect your organization with our award-winning products</p></div>
                                                </div>
                                                <div className="wt-icon-card bg-white shadow">
                                                    <div className="wt-card-header"><i className="flaticon-repair site-text-primary" /><span className="title-style-2 site-text-secondry">Efficient Project Management</span></div>
                                                    <div className="wt-card-content"><p>We are Prous to Protect your organization with our award-winning products</p></div>
                                                </div>
                                                <div className="wt-icon-card bg-white shadow">
                                                    <div className="wt-card-header"><i className="flaticon-repair site-text-primary" /><span className="title-style-2 site-text-secondry">Cutting-edge Technology</span></div>
                                                    <div className="wt-card-content"><p>We are Prous to Protect your organization with our award-winning products</p></div>
                                                </div>
                                                <div className="wt-icon-card bg-white shadow">
                                                    <div className="wt-card-header"><i className="flaticon-repair site-text-primary" /><span className="title-style-2 site-text-secondry">Safety Protocols</span></div>
                                                    <div className="wt-card-content"><p>We are Prous to Protect your organization with our award-winning products</p></div>
                                                </div>
                                                <div className="wt-icon-card bg-white shadow">
                                                    <div className="wt-card-header"><i className="flaticon-repair site-text-primary" /><span className="title-style-2 site-text-secondry">Collaboration with Clients</span></div>
                                                    <div className="wt-card-content"><p>We are Prous to Protect your organization with our award-winning products</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hilite-large-title title-style-2">
                            <span>What we do</span>
                        </div>
                    </div>
            </>
        );
    }
};

export default WhatWeDo1;