import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Segments/Switcher';

var bgimage = require('./../../images/background/footer-bg.png');

class Footer extends React.Component {
    constructor(props) {
        super(props);
        
        var homepage4 = /\/home-transport/i;
        var homepage5 = /\/home-agriculture/i;
        
        if(homepage4.test(window.location.href)){
            this.currentstate = { logo: require('./../../images/logo-2-light.png') };
        }else if(homepage5.test(window.location.href)){
            this.currentstate = { logo: require('./../../images/logo-4-light.png') };
        }else{
            this.currentstate = { logo: require('./../../images/logo-light.webp') };
        }
        
    }
    updateFooterLogo = (updatedlogo) => { this.currentstate.logo = updatedlogo.default; }

    render() {

        return (
            <>
                <footer className="site-footer footer-large footer-dark text-white footer-style1">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top bg-no-repeat bg-bottom-right" style={{ backgroundImage: 'url('+bgimage.default+')' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <div className="footer-h-left">
                                        <div className="widget widget_about">
                                            <div className="logo-footer clearfix">
                                                <NavLink to="/"><img className='logo-custom-footer' src={this.currentstate.logo} alt="" /></NavLink>
                                            </div>
                                            <p>Our firm specializes in reliable, innovative construction solutions, from small renovations to large-scale projects across healthcare, industrial, and residential sectors, including specialized services like interior fit-outs and landscaping.</p>
                                        </div>
                                        <div className="widget recent-posts-entry">
                                            <ul className="widget_address">
                                                <li><i className="fa fa-map-marker" />Office 515 , 516 - 43B Street</li>
                                                <li><i className="" />RAK Tower , Ras AL Khaimah, UAE</li>
                                                <li><i className="fa fa-envelope" /><a className="custom-bg-check" href="mailto:info@skylandconstruction.com">info@skylandconstruction.com</a></li>
                                                <li><i className="fa fa-phone" /><a className="custom-bg-check" href="tel:+971 7 243 5757"> +971 7 243 5757 </a> </li>
                                            </ul>
                                        </div>
                                        <div className="widget recent-posts-entry m-top-custom-40">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td><img className='logo-custom-footer' src={require("./../../images/quality/uae.webp")} alt="" /></td>
                                                    <td><img className='logo-custom-footer' src={require("./../../images/quality/iaf.webp")} alt="" /></td>
                                                    <td><img className='logo-custom-footer' src={require("./../../images/quality/quality.webp")} alt="" /></td>
                                                    <td><img className='logo-custom-footer' src={require("./../../images/quality/ias.webp")} alt="" /></td>
                                                </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                        <ul className="social-icons  wt-social-links footer-social-icon">
                                            <li><NavLink to={"https://g.co/kgs/439tJYo"} className="fa fa-google" /></li>
                                            <li><NavLink to={"https://www.instagram.com/skyland_bc/"} className="fa fa-instagram" /></li>
                                            <li><NavLink to={"https://www.facebook.com/skylandconstruction"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"https://twitter.com/skyland_bc"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"https://youtu.be/skylandconstruction"} className="fa fa-youtube" /></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12 col-sm-12">
                                    <div className="row footer-h-right">
                                        <div className="col-lg-5 col-md-4">
                                            <div className="widget widget_services">
                                                <h3 className="widget-title">Helpful links</h3>
                                                <ul>
                                                    <li><NavLink to={"/"}>Home</NavLink></li>
                                                    <li><NavLink to={"/about-1"}>Who We Are</NavLink></li>
                                                    <li><NavLink to={"/services-epc"}>What We Do</NavLink></li>
                                                    <li><NavLink to={"/projects"}>Journey So Far</NavLink></li>
                                                    <li><NavLink to={"/faq"}>Common Concerns</NavLink></li>
                                                    <li><NavLink to={"/contactus"}>Get In Touch</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-8">
                                            <div className="widget widget_services">
                                                 <h3 className="widget-title">Business Hours</h3>
                                                <ul>
                                                    <li className="underline_contact">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                Sunday
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-my-right">
                                                                8:00 - 18:00
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="underline_contact">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                Monday
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-my-right">
                                                                8:00 - 18:00
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="underline_contact">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                Tuesday
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-my-right">
                                                                8:00 - 18:00
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="underline_contact">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                Wednesday
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-my-right">
                                                                8:00 - 18:00
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="underline_contact">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                Thursday
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-my-right">
                                                                8:00 - 18:00
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="underline_contact">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                                                Friday
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-my-right">
                                                                8:00 - 13:00
                                                            </div>
                                                        </div>
                                                    </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget widget_newsletter">
                                        <h3 className="widget-title">Connect US</h3>
                                        <p>Subscribe to our newsletter to receive latest news on our services.</p>
                                        <div className="newsletter-input">
                                            <div className="input-group">
                                                <input id="email" type="text" className="form-control" name="email" placeholder="Enter your email" />
                                                <div className="input-group-append">
                                                    <button type="button" className="input-group-text nl-search-btn text-black site-bg-primary title-style-2">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="wt-footer-bot-left d-flex justify-content-between">
                                <span className="copyrights-text">Copyright © 2023 <span className="site-text-primary">Skyland construction</span></span>
                                <ul className="copyrights-nav">
                                    <li><NavLink to={"Terms&amp;Condition"}>Terms&amp;Condition</NavLink></li>
                                    <li><NavLink to={"PrivacyPolicy"}>Privacy Policy</NavLink></li>
                                    <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />

            </>
        );
    };
};

export default Footer;