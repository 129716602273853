import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';

const logos = [
    {image: require('./../../images/client-logo/w1.webp')},
    {image: require('./../../images/client-logo/w2.webp')},
    {image: require('./../../images/client-logo/w3.webp')},
    {image: require('./../../images/client-logo/w4.webp')},
    {image: require('./../../images/client-logo/w5.webp')},
    {image: require('./../../images/client-logo/w6.webp')},
    {image: require('./../../images/client-logo/w7.webp')},
    {image: require('./../../images/client-logo/w8.webp')},
    {image: require('./../../images/client-logo/w9.webp')},
    {image: require('./../../images/client-logo/w10.webp')},
    {image: require('./../../images/client-logo/w11.webp')},
    {image: require('./../../images/client-logo/w12.webp')},
    {image: require('./../../images/client-logo/w13.webp')},
    {image: require('./../../images/client-logo/w14.webp')},
    {image: require('./../../images/client-logo/w15.webp')},
    {image: require('./../../images/client-logo/w16.webp')},
    {image: require('./../../images/client-logo/w17.webp')},
    {image: require('./../../images/client-logo/w18.webp')},
    {image: require('./../../images/client-logo/w19.webp')},
    {image: require('./../../images/client-logo/w20.webp')},
    {image: require('./../../images/client-logo/w21.webp')},
    {image: require('./../../images/client-logo/w22.webp')},
    {image: require('./../../images/client-logo/w23.webp')},
    {image: require('./../../images/client-logo/w24.webp')},
    {image: require('./../../images/client-logo/w25.webp')},
    {image: require('./../../images/client-logo/w26.webp')},
    {image: require('./../../images/client-logo/w27.webp')},
    {image: require('./../../images/client-logo/w28.webp')},
    {image: require('./../../images/client-logo/w29.webp')},
    {image: require('./../../images/client-logo/w30.webp')},
    {image: require('./../../images/client-logo/w31.webp')},
    {image: require('./../../images/client-logo/w32.webp')},
    {image: require('./../../images/client-logo/w33.webp')},
]

class ClientsLogo10 extends React.Component {
    render() {
        const options = {
            loop:false,
            nav:true,
            dots: true,
            margin:0,
            autoplay:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items : 5,
            responsive:{
                3000:{
                    items:5
                },
            }
        };
        return (
            <>
                <div className="section-full bg-gray bg-gray ">
                    <div className="container">
                        <div className="section-content p-tb30 owl-btn-vertical-center">
                                <OwlCarousel className="owl-carousel home-client-carousel-2" {...options}>
                                    {logos.map((item, index) => (
                                        <div className="item" key={index} data-id={index}>
                                            <div className="ow-client-logo">
                                                <div className="client-logo client-logo-media client-logo2-media">
                                                    <NavLink to={"#"} ><img src={item.image} alt="" /></NavLink></div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ClientsLogo10;