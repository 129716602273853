import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [{
        count: '01',
        image: require('./../../images/ui-10/services/1.jpg'),
        flaticon: 'flaticon-oil',
        title1: 'Engineering,',
        title2: 'Procurement & Construction',
        description: 'Streamline Your Project with Expert EPC Services! Our integrated Engineering, Procurement, and Construction (EPC) approach ensures your project is delivered efficiently, combining innovative design, strategic material sourcing, and skilled construction. Experience timely, cost-effective, and high-quality project completion with our professional expertise. Lets build your vision into reality, creating lasting value together'
    },
    {
        count: '02',
        image: require('./../../images/ui-10/services/1.jpg'),
        flaticon: 'flaticon-conveyor',
        title1: 'Civil & Structural',
        title2: 'Services',
        description: 'Explore our comprehensive Civil & Structural Services tailored to meet the diverse needs of your construction project. Our experienced team offers expert guidance and support from the initial design phase through to completion, ensuring structural integrity, compliance with regulations, and cost-effective solutions. Whether its site evaluations, foundation design, structural analysis, or project management, we deliver excellence and innovation every step of the way. Partner with us for dependable, high-quality Civil & Structural Services that lay the foundation for success in your construction endeavors'
    },
    {
        count: '03',
        image: require('./../../images/ui-10/services/1.jpg'),
        flaticon: 'flaticon-robotic-arm',
        title1: 'Infrastructure',
        title2: 'Services',
        description: 'Our comprehensive infrastructural services cover a spectrum of vital elements essential for efficient operations. From constructing access roads for Overhead Lines (OHL) to erecting OHL Tower Platforms and establishing sturdy foundations, we ensure the backbone of your project is solid and reliable. Ourl and Gas Lines, facilitating seamless energy supply. Additionally, our solutions encompass Storm Water management to mitigate environmental impact effectively. Moreover, we specialize in intricate 132KV cabling works, guaranteeing seamless electrical connectivity to power your operations. With our commitment to quality and precision, we deliver infrastructural solutions tailored to efficiency, and safety every step of the way'
    },
    {
        count: '04',
        image: require('./../../images/ui-10/services/1.jpg'),
        flaticon: 'flaticon-industry',
        title1: 'Transport & Heavy',
        title2: 'Equipments',
        description: 'Explore our top-notch Transport & Heavy Equipment services, designed to streamline your construction operations. From transporting materials and equipment to providing a fleet of robust heavy machinery, we offer tailored solutions to meet your projects demands. Our fleet is meticulously maintained, ensuring reliability and efficiency on-site, while our experienced operators ensure smooth operation and safety compliance. With a focus on responsiveness and flexibility, we are committed to supporting your projects success every step of the way. Choose our Transport & Heavy Equipment services for dependable solutions that keep your construction moving forward'
    },
    {   
        count: '05',
        flaticon: 'flaticon-scythe',
        title1: 'Fabrication & Erection',
        title2: 'Services',
        description: 'Unlock precision and reliability with our Fabrication & Erection Services tailored for your construction needs. Our skilled craftsmen and advanced fabrication facilities ensure the highest quality standards in metalwork, steel structures, and customized components. From concept to completion, we offer seamless project management, meticulous planning, and efficient execution, delivering on-time and within budget. Whether its structural steel fabrication, industrial piping, or architectural metalwork, trust our expertise to bring your vision to life with precision and excellence. Elevate your construction projects with our Fabrication & Erection Services, where quality meets innovation.'
    },
    {
        count: '06',
        flaticon: 'flaticon-helmet',
        title1: 'MEP',
        title2: 'Services',
        description: 'Empower your construction projects with our MEP (Mechanical, Electrical, Plumbing) Services, tailored to optimize functionality, efficiency, and sustainability. Our expert team provides comprehensive solutions encompassing design, installation, and maintenance, ensuring seamless integration of mechanical, electrical, and plumbing systems. From energy-efficient HVAC solutions to advanced electrical systems and precise plumbing installations, we deliver excellence at every stage. With a focus on innovation and compliance, we strive to enhance the performance and longevity of your building infrastructure. Partner with us for reliable MEP Services that elevate your construction endeavors to new heights of success.'
    },
    {
        count: '07',
        flaticon: 'flaticon-scythe',
        title1: 'Civil Defense',
        title2: 'Services',
        description: 'Safeguard your construction projects with our Civil Defense Services, dedicated to ensuring compliance with safety regulations and emergency preparedness. Our experienced team offers comprehensive solutions for fire protection systems, emergency evacuation planning, and risk assessment. From design consultation to installation and training, we prioritize safety at every stage of your project. With a commitment to excellence and reliability, we provide peace of mind knowing your construction site is equipped to handle any emergency effectively. Trust our Civil Defense Services to protect your project, your people, and your investment.'
    },
    {
        count: '08',
        flaticon: 'flaticon-helmet',
        title1: 'Fit-Out',
        title2: 'Services',
        description: 'Elevate your spaces with our bespoke Fit-Out Services, tailored to transform your vision into reality. From concept design to meticulous execution, our experienced team specializes in interior fit-outs that reflect your brand identity and enhance functionality. Whether its office spaces, retail environments, or hospitality venues, we bring creativity, craftsmanship, and attention to detail to every project. With a focus on quality materials, innovative solutions, and timely delivery, we ensure a seamless transition from concept to completion. Partner with us to unlock the full potential of your space with our comprehensive Fit-Out Services.'
    },
]

var bnr1 = require('./../../images/background/bg-7.jpg');

class Services13 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b40 bg-no-repeat bg-bottom-right bg-cover" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center">
                                <div className="wt-small-separator site-text-primary">
                                    <div className="sep-leaf-left" />
                                    <div>The Best Industry services</div>
                                    <div className="sep-leaf-right" />
                                </div>
                                <h2>High Performance Services For Design-Construction-Investments Collaboration!</h2>
                            </div>
                            {/* TITLE END*/}
                            <div className="row d-flex justify-content-center">
                                {services.map((item, index) => (
                                    <div key={index} className="col-lg-3 col-md-6 col-sm-12 m-b50">
                                        <div className="service-border-box">
                                            <div className="wt-box service-box-1 bg-white">
                                                <div className="service-box-title title-style-2 site-text-secondry">
                                                    <span className="s-title-one">{item.title1}</span>
                                                    <span className="s-title-two">{item.title2}</span>
                                                </div>
                                                <div className="service-box-content">
                                                    <p>{item.description}</p>
                                                    <NavLink to={"/services-steel-structure"} className="site-button-link">Read More</NavLink>
                                                </div>
                                                <div className="wt-icon-box-wraper">
                                                    <div className="wt-icon-box-md site-bg-primary">
                                                        <span className="icon-cell text-white"><i className={item.flaticon} /></span>
                                                    </div>
                                                    <div className="wt-icon-number"><span>{item.count}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services13;