import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const services = [
    {
        count: '01',
        title1: 'Engineering,',
        title2: 'Procurement 1 & Construction',
        flaticon: require('./../../images/icons/1.png'),
        description: 'Maximize project efficiency with our EPC services! Our integrated approach blends innovative design, strategic sourcing, and skilled construction for timely, cost-effective, high-quality completion. Lets build your vision into lasting value together.',
        url: '/about-1?section=01'
    },
    {
        count: '02',
        title1: 'Civil & Structural',
        title2: 'Services',
        flaticon: require('./../../images/icons/2.png'),
        description: 'Explore our bespoke Civil & Structural Services tailored for your project needs. From design to completion, ensure integrity, compliance, and cost-effectiveness. Partner with us for dependable, high-quality solutions that lay the foundation for construction success.',
        url: '/about-1?section=02'
    },
    {
        count: '03',
        title1: 'Infrastructure',
        title2: 'Services',
        flaticon: require('./../../images/icons/3.png'),
        description: 'Our comprehensive infrastructural services ensure efficient operations. From access roads for OHL Tower, Towers Platforms, Towers foundations, Gas Lines, Storm Water management, 11KV, 33KV, 132KV U/G power cabling networks, we guarantee reliability, efficiency, and safety at every step.',
        url: '/about-1?section=03'
    },
    {
        count: '04',
        title1: 'Transport & Heavy',
        title2: 'Equipments',
        flaticon: require('./../../images/icons/4.png'),
        description: 'Explore our Transport & Heavy Equipment services for streamlined construction operations. From material transport to robust machinery, we offer tailored solutions. Meticulously maintained fleet and experienced operators ensure reliability, efficiency, and safety on-site. Choose us for dependable solutions.',
        url: '/about-1?section=04'
    },
    {
        count: '05',
        title1: 'Fabrication',
        title2: '& Erection',
        flaticon: require('./../../images/icons/5.png'),
        description: 'Experience precision and reliability with our tailored Fabrication & Erection Services. Our skilled craftsmen and advanced facilities ensure top-quality metalwork and steel structures. From concept to completion, trust our expertise for on-time, within-budget delivery.',
        url: '/about-1?section=05'
    },
    {
        count: '06',
        title1: 'M E P',
        title2: 'Services',
        flaticon: require('./../../images/icons/6.png'),
        description: 'Enhance construction projects with our MEP Services, optimizing functionality, efficiency, and sustainability. From design to maintenance, we integrate mechanical, electrical, and plumbing systems seamlessly. Partner for reliable solutions that elevate your endeavors to new heights',
        url: '/about-1?section=06'
    },
    {
        count: '07',
        title1: 'Civil Defense',
        title2: 'Services',
        flaticon: require('./../../images/icons/7.png'),
        description: 'Ensure project safety with our Civil Defense Services, prioritizing compliance and emergency preparedness. Trust our reliable solutions for peace of mind, from fire protection to evacuation planning. Count on us for effective emergency response at your construction site',
        url: '/about-1?section=07'
    },
    {
        count: '08',
        title1: 'Fit-Out',
        title2: 'Services',
        flaticon: require('./../../images/icons/8.png'),
        description: 'Transform your vision into reality with our bespoke Fit-Out Services. Specializing in interior fit-outs, we enhance functionality and reflect your brand identity. Partner with us for creativity, craftsmanship, and timely delivery. Unlock your spaces full potential.',
        url: '/about-1?section=08'
    },
    {
        count: '09',
        title1: 'Engineering',
        title2: 'Survey',
        flaticon: require('./../../images/icons/9.png'),
        description: 'An engineering survey is a crucial step in construction projects, involving precise measurement and mapping of land and structures using advanced tools like total stations and GPS. It ensures accurate site layouts and optimal planning for successful construction.',
        url: '/about-1?section=09'
    }
]

var bnr1 = require('./../../images/background/bg-3.webp');

class Services1 extends React.Component {
    render() {

        const options = {
            loop:true,
            autoplay:true,
            nav:true,
            dots: false,	
            margin:10,
            navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
            responsive:{
                0:{
                    items:1,
                },
                640:{
                    items:1,
                },			
                767:{
                    items:2,
                 },				
                991:{
                    items:2,
                },
                1366:{
                    items:2,
                },			
                1400:{
                    items:3
                }		
            }
        };
        
        return (
            <>
                <div className="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover services-main-section" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <div className="overlay-main site-bg-secondry opacity-08" />
                    <div className="section-content services-section-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="services-section-content-left">
                                    {/* TITLE START*/}
                                    <div className="left wt-small-separator-outer text-white">
                                        <div className="wt-small-separator site-text-primary">
                                            <div className="sep-leaf-left" />
                                            <div>The Best Construction Industry services</div>
                                            <div className="sep-leaf-right" />
                                        </div>
                                        <h2>High Performance Services For Design<br/> Construction Investments Collaboration!</h2>
                                        <p>Maximize project success with our Design-Construction-Investment Services! Streamlining key processes for efficiency, our experts deliver top results, turning visions into reality and ensuring optimal investment returns. Partner with us for unmatched collaboration and innovation</p>
              <NavLink to="/contactus" className="site-button site-btn-effect">Contact Us</NavLink>
                                    </div>
                                    {/* TITLE END*/}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="services-section-content-right">
                                    <OwlCarousel className="owl-carousel services-slider owl-btn-vertical-center" {...options}>

                                        {services.map((item, index) => (
                                            <div key={index} className="item">
                                            <div className="wt-box service-box-1 bg-white">
                                                <div className="service-box-title title-style-2 site-text-secondry">
                                                    <span className="s-title-one">{item.title1}</span>
                                                    <span className="s-title-two">{item.title2}</span>
                                                </div>
                                                <div className="service-box-content">
                                                    <p>{item.description}</p>
                                                    <NavLink to={item.url} className="site-button-link">Read More</NavLink>
                                                </div>
                                                <div className="wt-icon-box-wraper">
                                                    <div className="wt-icon-box-md site-bg-primary">
                                                        <span className="icon-cell text-white">
                                                            <img src={item.flaticon} />
                                                        </span>
                                                    </div>
                                                    <div className="wt-icon-number"><span>{item.count}</span></div>
                                                </div>
                                            </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services1;