import React from 'react';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';
import { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

var bnrimg = require('./../../images/banner/5.jpg');
var bnr1 = require('./../../images/background/bg-7.jpg');
var bgimg = require('./../../images/background/bg-map2.png');

const handleSubmit = (e) => {

    e.preventDefault();

    emailjs.init("YtGEMUsnFSuEpzL6D")

    const serviceId = "service_2a7b1jx";
    const templateId = "template_sst7idj";

    try {

        emailjs.send(serviceId, templateId, {
            name: e.username,
            recipient: e.target.email.value,
            message : "following information received from the contact!" +
                "Name: " + e.target.username.value + "\n" +
                "Email: " + e.target.email.value + "\n" +
                "Phone: " + e.target.phone.value + "\n" +
                "Subject: " + e.target.subject.value + "\n" +
                "Message: " + e.target.message.value + "\n"
        });

        NotificationManager.success('Success message', 'Email Successfully Sent!');

        return false;

    } catch (error) {
        console.log(error);
    } finally {


    }

}

const sendMail = (e) => {


}

class ContactUs extends React.Component {

    render() {

        window.scrollTo(0, 0);

        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Contact Us" pagename="Contact Us" bgimage={bnrimg} />
                    {/* INNER PAGE BANNER END */}
                    {/* SECTION CONTENTG START */}
                    {/* CONTACT FORM */}
                    <div className="section-full  p-t80 p-b50 bg-cover" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <div className="section-content">
                            <div className="container">
                                <div className="contact-one">
                                    {/* CONTACT FORM*/}
                                    <div className="row  d-flex justify-content-center flex-wrap">
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <form className="cons-contact-form" method="post" action="#" onSubmit={handleSubmit}>
                                                {/* TITLE START */}
                                                <div className="section-head left wt-small-separator-outer">
                                                    <div className="wt-small-separator site-text-primary">
                                                        <div className="sep-leaf-left" />
                                                        <div>Contact Form</div>
                                                        <div className="sep-leaf-right" />
                                                    </div>
                                                    <h2>Get In Touch</h2>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <input name="username" type="text" required  defaultValue="" className="form-control" placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <input name="email" type="text" className="form-control" defaultValue="" required placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <input name="phone" type="text" className="form-control" defaultValue="" required placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <input name="subject" type="text" className="form-control" defaultValue="" required placeholder="Subject" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea name="message" className="form-control" rows={4} placeholder="Message" defaultValue="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="site-button site-btn-effect">Submit Now</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="contact-info">
                                                <div className="contact-info-inner">
                                                    {/* TITLE START*/}
                                                    <div className="section-head left wt-small-separator-outer">
                                                        <div className="wt-small-separator site-text-primary">
                                                            <div className="sep-leaf-left" />
                                                            <div>Contact info</div>
                                                            <div className="sep-leaf-right" />
                                                        </div>
                                                        <h2>Our Full Info</h2>
                                                    </div>
                                                    {/* TITLE END*/}
                                                    <div className="contact-info-section" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                                                        <div className="wt-icon-box-wraper left m-b30">
                                                            <div className="icon-content">
                                                                <h3 className="m-t0 site-text-primary" style={{ marginBottom: '0px' }}>Phone number</h3>
                                                                <p>
                                                                    <a className="custom-bg-check" href="tel:+971 7 243 5757"> +971 7 243 5757 </a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-icon-box-wraper left m-b30">
                                                            <div className="icon-content">
                                                                <h3 className="m-t0 site-text-primary" style={{ marginBottom: '0px' }}>Email address</h3>
                                                                <p>
                                                                    <a className="custom-bg-check" href="mailto:info@skylandconstruction.com">info@skylandconstruction.com</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-icon-box-wraper left m-b30">
                                                            <div className="icon-content">
                                                                <h3 className="m-t0 site-text-primary" style={{ marginBottom: '0px' }}>Address info</h3>
                                                                <p>
                                                                    Office 515 , 516 - 43B Street
                                                                    RAK Tower , Ras AL Khaimah - United Arab Emirates
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

{/*
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.195542524527!2d55.96635867680936!3d25.79712220713792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef6714e5705b051%3A0x1a4d2aa3eef41cce!2sSky%20Land%20Construction!5e0!3m2!1sen!2s!4v1713473132146!5m2!1sen!2s" width="100%" height="600" style={{ border:0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
*/}
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1231.7075278123116!2d55.96876546554292!3d25.796675349067055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef6714e5705b051%3A0x1a4d2aa3eef41cce!2sSky%20Land%20Construction!5e1!3m2!1sen!2sfr!4v1716755737657!5m2!1sen!2sfr" width="100%" height="600" style={{ border:0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    {/* GOOGLE MAP */}

                </div>

                <Footer />

            </>
        );
    };
};

export default ContactUs;