import React from 'react';
import {NavLink} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const logos = [
    {image: require('./../../images/client-logo/w1.png')},
    {image: require('./../../images/client-logo/w2.png')},
    {image: require('./../../images/client-logo/w3.png')},
    {image: require('./../../images/client-logo/w4.png')},
    {image: require('./../../images/client-logo/w5.png')},
    {image: require('./../../images/client-logo/w6.png')},
    {image: require('./../../images/client-logo/w7.png')},
    {image: require('./../../images/client-logo/w8.png')},
    {image: require('./../../images/client-logo/w9.png')},
    {image: require('./../../images/client-logo/w10.png')},
    {image: require('./../../images/client-logo/w11.png')},
    {image: require('./../../images/client-logo/w12.png')},
    {image: require('./../../images/client-logo/w13.png')},
    {image: require('./../../images/client-logo/w14.png')},
    {image: require('./../../images/client-logo/w15.png')},
    {image: require('./../../images/client-logo/w16.png')},
    {image: require('./../../images/client-logo/w17.png')},
    {image: require('./../../images/client-logo/w18.png')},
    {image: require('./../../images/client-logo/w19.png')},
    {image: require('./../../images/client-logo/w20.webp')},
    {image: require('./../../images/client-logo/w21.png')},
    {image: require('./../../images/client-logo/w22.png')},
    {image: require('./../../images/client-logo/w23.png')},
    {image: require('./../../images/client-logo/w24.png')},
    {image: require('./../../images/client-logo/w25.png')},
    {image: require('./../../images/client-logo/w26.png')},
    {image: require('./../../images/client-logo/w27.png')},
    {image: require('./../../images/client-logo/w28.png')},
    {image: require('./../../images/client-logo/w29.png')},
    {image: require('./../../images/client-logo/w30.png')},
    {image: require('./../../images/client-logo/w31.png')},
    {image: require('./../../images/client-logo/w32.png')},
    {image: require('./../../images/client-logo/w33.png')},
]

class ClientsLogo1 extends React.Component {
    render() {
        const options = {
            loop: true,
            nav: false,
            dots: false,
            margin: 20,
            autoplay: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                3000: {
                    items: 2,
                },
            }
        };
        return (
            <>
                <div className="section-full bg-gray bg-gray p-t90 p-b50">
                    <div className="container">
                        <div className="section-content p-tb30 owl-btn-vertical-center">
                            <OwlCarousel className="owl-carousel home-client-carousel-2" {...options}>
                                {logos.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="ow-client-logo">
                                            <div className="client-logo client-logo-media">
                                                <NavLink to={"#"}><img src={item.image} alt=""/></NavLink></div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>

                        </div>
                    </div>
                </div>
                <div className="section-full bg-gray bg-gray p-t90 p-b50">
                    <div className="container">
                        <div className="section-content p-tb30 owl-btn-vertical-center">
                            <OwlCarousel className="owl-carousel home-client-carousel-2" {...options}>
                                {logos.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="ow-client-logo">
                                            <div className="client-logo client-logo-media">
                                                <NavLink to={"#"}><img src={item.image} alt=""/></NavLink></div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ClientsLogo1;