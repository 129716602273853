import React from 'react';
import { NavLink } from 'react-router-dom';

class About2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer">
                    <div className="welcome-section-top bg-white p-t80 p-b50">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-6 col-md-12 m-b30">
                                    <div className="welcom-to-section">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Who we are</div>
                                                <div className="sep-leaf-right" />
                                            </div>
                                        </div>
                                        <h2>Building Futures: Sustainable and Innovative Construction</h2>
                                        {/* TITLE END*/}
                                        <ul className="site-list-style-one">
                                            {/* <li>Quality Control System , 100% Satisfaction Guarantee</li>
                                            <li>Unrivalled Workmanship, Professional and Qualified</li>
                                            <li>Environmental Sensitivity, Personalised Solutions</li> */}
                                        </ul>
                                        <p>Sky Land Construction is an engineering, construction and project management company established in 2009 & our affiliated company Al Jazeera Al Hamra Transport LLC established in 2013 in the state of Ras AL Khaimah, United Arab Emirates. We are providing our clients with reliable solution to their most complex construction challenges. Today, we are recognized as a reliable contracting company, for our strength in traditional construction methods and for our creative, fresh approach to cutting edge technologies and delivery systems.</p>
                                        <p>Our expertise spans the construction industry spectrum from smaller renovations to million dirhams projects. We specialize in industrial facilities, commercial, residential and commercial villas, Infrastructural works as well as internal fitout projects such as home interiors, Land Scaping and interlocking works etc. Our team of construction professionals offers a single source solution for all of your construction related needs. Whether its restoration, site preparation, a new facility or facility renovation, we have the experience and personnel to provide the highest quality construction on schedule and within budget</p>
                                        <p>We support our clients from project inception, to the commissioning of the fully operational facility. And our network of local offices enables us to offer our clients the dual advantage of a strong local presence and broad geographic reach.</p>
                                        <div className="welcom-to-section-bottom d-flex justify-content-between">
                                            <div className="welcom-btn-position"><NavLink to={"/about-1"} className="site-button-secondry site-btn-effect">More About</NavLink></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 m-b30">
                                    <div className="img-colarge2">
                                        <div className="colarge-2 slide-right"><img src={require('./../../images/colarge/s2.jpg')} alt="" /></div>
                                        <div className="colarge-2-1 slide-right-new"><img src={require('./../../images/colarge/s1.webp')} alt="" /></div>
                                        <div className="since-year-outer2"><div className="since-year2"><span>Since</span><strong>2009</strong></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About2;