import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/banner/8.jpg');

class Home3 extends React.Component {

    render() {

        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Terms & Conditions" pagename="Terms & Conditions" bgimage={bnrimg} />
                    {/* INNER PAGE BANNER END */}
                    {/* FAQ SECTION START */}
                    <div className="section-full p-t80 p-b50 bg-white">
                        <div className="container">
                            {/* TITLE START*/}
                            {/* TITLE END*/}
                            <div className="section-content">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                        {/* Accordian */}

                                        <div className="wt-accordion acc-bg-gray m-b50 about-section-three-acc" id="accordion">

                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            1 - Definitions
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                1.1 - Buyer: Sky Land Construction LLC OPC.
                                                            </li>
                                                            <li>
                                                                1.2 - Supplier/Subcontractor: The entity receiving the purchase order and responsible for supplying goods or services.
                                                            </li>
                                                            <li>
                                                                1.3 - Goods: Items specified in the Purchase Order that the Supplier agrees to deliver.
                                                            </li>
                                                            <li>
                                                                1.4 - Services: Activities, including labor and expertise, that the Subcontractor agrees to perform as specified in the Purchase Order.
                                                            </li>
                                                            <li>
                                                                1.5 - Purchase Order (PO): The document outlining the agreed specifications, quantities, delivery timelines, and costs for goods or services.
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingThree">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            2 - Scope of Work
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                2.1 - The Supplier/Subcontractor agrees to supply the goods or perform the services described in the Purchase Order, including all labor, materials, tools, equipment, transportation, and supervision necessary.
                                                            </li>
                                                            <li>
                                                                2.2 - Work shall be conducted according to all specifications, drawings, and any applicable project standards provided by Sky Land Construction LLC OPC.
                                                            </li>
                                                            <li>
                                                                2.3 - Changes to the scope, specifications, or quantities must be authorized in writing by Sky Land Construction LLC OPC.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingFour">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            3 - Price, Taxes, and Payment Terms
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                3.1 - Price: The prices listed in the Purchase Order are fixed and inclusive of all costs, such as applicable taxes, duties, packaging, transportation, and insurance unless otherwise specified.
                                                            </li>
                                                            <li>
                                                                3.2 - Additional Charges: Any extra charges require prior written consent from Sky Land Construction LLC OPC.
                                                            </li>
                                                            <li>
                                                                3.3 - Payment Terms: Payment will be made within specified on PO from the date of receipt of goods or completion of services and submission of a proper invoice. The invoice must include:
                                                            </li>
                                                            <li>
                                                                3.4 - PO number
                                                            </li>
                                                            <li>
                                                                3.5 - Description and quantity of goods or services delivered
                                                            </li>
                                                            <li>
                                                                3.6 - Itemized pricing and any applicable taxes
                                                            </li>
                                                            <li>
                                                                3.7 - Any relevant supporting documentation [delivery notes, work completion certificates, etc.]
                                                            </li>
                                                            <li>
                                                                3.8 - Currency: Payments will be made in [AED] unless otherwise specified.
                                                            </li>
                                                            <li>
                                                                3.9 - Late Payment Interest: No interest or late fees will be charged unless expressly agreed upon in writing.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingFive">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            4 - Delivery, Inspection, and Acceptance
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                4.1 - Delivery Requirements: Delivery must be made according to the schedule and address specified in the PO. Partial or early deliveries require written approval.
                                                            </li>
                                                            <li>
                                                                4.2 - Packaging and Labeling: Goods must be securely packaged, labeled, and identified per agreed specifications to prevent damage and ensure ease of handling.
                                                            </li>
                                                            <li>
                                                                4.3 - Inspection and Rejection:
                                                            </li>
                                                            <li>
                                                                4.4 - Sky Land Construction LLC OPC reserves the right to inspect goods or services upon delivery to confirm compliance with PO requirements.
                                                            </li>
                                                            <li>
                                                                4.5 - Goods/services failing to meet the agreed standards or specifications may be rejected. The Supplier/Subcontractor must promptly rectify or replace any rejected goods or services at no additional cost.
                                                            </li>
                                                            <li>
                                                                4.6 - Sky Land Construction LLC OPC may, at its discretion, arrange for the correction or replacement of rejected items, with costs deducted from the Supplier/Subcontractor's payment.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingSix">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            5 - Quality Standards and Warranty
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                5.1 - Quality Standards: All goods and services must meet the quality standards specified in the PO, relevant industry standards, and any applicable regulations.
                                                            </li>
                                                            <li>
                                                                5.2 - Warranty
                                                            </li>
                                                            <li>
                                                                5.3 - Supplier/Subcontractor warrants that goods will be free from defects in material, workmanship, and design for [12 months] from the delivery date.
                                                            </li>
                                                            <li>
                                                                5.4 - For services, the Supplier/Subcontractor warrants that all work will be performed professionally and according to industry standards.
                                                            </li>
                                                            <li>
                                                                5.5 - Goods/services failing to meet the agreed standards or specifications may be rejected. The Supplier/Subcontractor must promptly rectify or replace any rejected goods or services at no additional cost.
                                                            </li>
                                                            <li>
                                                                5.6 - Any defective goods or substandard services identified during the warranty period shall be corrected, replaced, or re-performed by the Supplier/Subcontractor at no cost to Sky Land Construction LLC OPC.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingSeven">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingSeven" aria-expanded="false" aria-controls="collheadingSeven">
                                                            6 - Health, Safety, and Environmental Compliance
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                6.1 - Supplier/Subcontractor agrees to comply with all health, safety, and environmental laws, as well as Sky Land Construction LLC OPC’s on-site protocols.
                                                            </li>
                                                            <li>
                                                                6.2 - All personnel must have the necessary safety training, PPE, and documentation to access work sites.
                                                            </li>
                                                            <li>
                                                                6.3 - Supplier/Subcontractor is responsible for any fines, liabilities, or damages resulting from non-compliance with safety or environmental standards.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingEight">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingEight" aria-expanded="false" aria-controls="collheadingEight">
                                                            7 - Confidentiality and Intellectual Property
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                7.1 - Confidentiality: Supplier/Subcontractor shall keep all information regarding this PO, including specifications, proprietary designs, processes, and trade secrets, confidential.
                                                            </li>
                                                            <li>
                                                                7.2 - Intellectual Property: Any inventions, designs, drawings, reports, or other intellectual property created in connection with this PO will be the property of Sky Land Construction LLC OPC unless otherwise agreed in writing.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingNine">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingNine" aria-expanded="false" aria-controls="collheadingNine">
                                                            8 - Liability, Indemnification, and Insurance
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                8.1 - Indemnification: The Supplier/Subcontractor agrees to indemnify, defend, and hold Sky Land Construction LLC OPC harmless against any claims, losses, or damages resulting from:
                                                            </li>
                                                            <li>
                                                                8.2 - Injuries or damages caused by the Supplier/Subcontractor's personnel or operations.
                                                            </li>
                                                            <li>
                                                                8.3 - Breach of these terms by the Supplier/Subcontractor.
                                                            </li>
                                                            <li>
                                                                8.4 - Insurance
                                                            </li>
                                                            <li>
                                                                8.5 - Supplier/Subcontractor must maintain adequate liability, workers' compensation, and other relevant insurance policies.
                                                            </li>
                                                            <li>
                                                                8.6 - Certificates of insurance must be provided to Sky Land Construction LLC OPC upon request.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingTen">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingTen" aria-expanded="false" aria-controls="collheadingTen">
                                                            9 - Force Majeure
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                9.1 - Neither party shall be held liable for any delay or failure to fulfill the terms of this PO due to unforeseen circumstances beyond their control, including natural disasters, government actions, strikes, or other force majeure events.
                                                            </li>
                                                            <li>
                                                                9.2 - In the event of a force majeure, the affected party shall notify the other party immediately and work together to resolve the situation promptly.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingEleven">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingEleven" aria-expanded="false" aria-controls="collheadingEleven">
                                                            10 - Termination
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                               10.1 - Termination for Convenience: Sky Land Construction LLC OPC reserves the right to cancel the PO, in whole or in part, for its convenience by giving written notice.
                                                            </li>
                                                            <li>
                                                                10.2 - Termination for Cause: If the Supplier/Subcontractor fails to comply with the terms of this PO or becomes insolvent, Sky Land Construction LLC OPC may terminate the PO immediately, with no liability for cancellation charges.
                                                            </li>
                                                            <li>
                                                                10.3 - Upon termination, the Supplier/Subcontractor must stop all work and deliver any completed goods or work to Sky Land Construction LLC OPC.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingTwelve">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingTwelve" aria-expanded="false" aria-controls="collheadingTwelve">
                                                            11 - Dispute Resolution
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                               11.1 - Parties agree to resolve disputes amicably and in good faith. If no resolution is reached within [30 Days], disputes shall be submitted to arbitration according to [Jurisdiction of UAE Law].
                                                            </li>
                                                            <li>
                                                                11.2 - The decision of the arbitrator(s) shall be final and binding.
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingThirteen">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingThirteen" aria-expanded="false" aria-controls="collheadingThirteen">
                                                            12 - Governing Law
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                                12.1 - This PO shall be governed by and construed in accordance with the laws of [ Jurisdiction of UAE Law].
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel force-font-18">
                                                <div className="acod-head" id="headingFourteen">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingFourteen" aria-expanded="false" aria-controls="collheadingFourteen">
                                                            13 - Entire Agreement
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">

                                                        <ul className="ul__list">
                                                            <li>
                                                               13.1 - This PO constitutes the entire agreement between Sky Land Construction LLC OPC and the Supplier/Subcontractor, superseding any prior communications or agreements, whether written or oral. Any amendments must be agreed upon in writing by both parties.
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ SECTION END */}
                </div>

                <Footer />

            </>
        );
    };
};

export default Home3;