import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/pic1.webp'),
        reviewername: 'Kings Tobacco FZ LLC',
        position: 'Client',
        review: 'Skyland Construction Company exceeded our expectations with their professionalism, meticulous attention to detail, and unwavering commitment to quality. We are delighted with the results and wholeheartedly endorse their services!'
    },
    {
        image: require('./../../images/testimonials/pic2.webp'),
        reviewername: 'Sarsan Heavy Engg.',
        position: 'Client',
        review: 'The team at Skyland Construction Company truly went above and beyond. From start to finish, they maintained open communication and ensured every aspect of our project was executed flawlessly. We couldnt be happier with the results!'
    },
    {
        image: require('./../../images/testimonials/pic3.webp'),
        reviewername: 'IMCO Engineering & Construction W.L.L',
        position: 'Client',
        review: 'Skyland Construction Company exceeded all of our expectations. Their expertise and dedication were evident in every phase of our project. We are grateful for their outstanding work and wouldnt hesitate to work with them again!'
    },
    {
        image: require('./../../images/testimonials/pic4.webp'),
        reviewername: 'Kirby Building System',
        position: 'Client',
        review: 'The team at Skyland Construction Company did an outstanding job on our renovation project. Their communication throughout the process was excellent, and the final result exceeded our expectations. We couldnt be happier with the outcome.!'
    }
]

class Testimonials1 extends React.Component {
    render() {
        const options = {
            loop:true,
            autoplay:true,
            nav:true,
            dots: false,	
            margin:30,
            navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
            responsive:{
                0:{
                    items:1,
                },
                1400:{
                    items:1
                }		
            }
        };
        return (
            <>
                <div className="section-full  p-t80 testimonial-1-outer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                {/* TITLE START*/}
                                <div className="left wt-small-separator-outer">
                                    <div className="wt-small-separator site-text-primary">
                                        <div className="sep-leaf-left" />
                                        <div>What our client say</div>
                                        <div className="sep-leaf-right" />
                                    </div>
                                    <h2>Happy With Customers &amp; Clients</h2>
                                    <p>Our success is measured by the satisfaction of our clients. Whether it's a residential remodel or a commercial build, we're devoted to delivering outstanding results that leave a lasting smile on our clients' faces.</p>
                                    <NavLink to={"/contactus"} className="site-button site-btn-effect">Contact Us</NavLink>
                                </div>
                                {/* TITLE END*/}
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="testimonial-1-content-outer">
                                    <OwlCarousel className="testimonial-1-content owl-carousel  owl-btn-top-right long-arrow-next-prev" {...options}>

                                        {testimonials.map((item, index) => (
                                            <div key={index} className="item">
                                                <div className="testimonial-1 bg-white">
                                                    <div className="testimonial-content">
                                                        <div className="testimonial-pic-block">
                                                            <div className="testimonial-pic">
                                                                <img src={item.image} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-text">
                                                            <i className="fa fa-quote-left" />
                                                            <p>{item.review}</p>
                                                        </div>
                                                        <div className="testimonial-detail clearfix">
                                                            <div className="testimonial-info">
                                                                <span className="testimonial-name  title-style-2 site-text-secondry">{item.reviewername}</span>
                                                                <span className="testimonial-position title-style-2 site-text-primary">{item.position}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-large-title title-style-2">
                        <span>Client says</span>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials1;