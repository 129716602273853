import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        image: require('./../../images/ui-10/services/1.jpg'),
        flaticon: require('./../../images/ui-10/icons/1.png'),
        title: 'Engineering, Procurement & Construction',
        description: 'Experience efficient project delivery with our EPC services! Our integrated approach ensures timely, cost-effective completion. Collaborate with us to turn your vision into lasting value. Leveraging cutting-edge technologies, we guarantee exceptional results. With established global relationships, we procure high-quality materials at minimized costs. Choose our EPC services for on-time, within-budget delivery. Collaborate with us for lasting value.',
        redirect:'/services-epc'
    },
    {
        count: '02',
        image: require('./../../images/ui-10/services/2.jpg'),
        flaticon: require('./../../images/ui-10/icons/2.png'),
        title: 'Civil & Structural Services',
        description: 'Experience our customized Civil & Structural Services, emphasizing integrity, compliance, and cost-effectiveness. From design to completion, we provide reliable solutions for construction success. Our team ensures high safety standards and full compliance with regulations, optimizing resources to deliver value without compromising quality. Let us bring your vision to life with our Civil & Structural Services.',
        redirect:'/services-automotive'
    },
    {
        count: '03',
        image: require('./../../images/ui-10/services/3.jpg'),
        flaticon: require('./../../images/ui-10/icons/3.png'),
        title: 'Infrastructure Services',
        description: 'Trust our comprehensive infrastructural services for seamless optimization. Specializing in constructing access roads for OHL Towers, Tower Platforms, and Foundations, we also manage Gas Lines, Storm Water, and underground power cabling networks from 11KV to 132KV. Our expertise ensures reliability, efficiency, and safety at every stage. Collaborate with us for successful infrastructural endeavors.',
        redirect:'/services-infrastructure'
    },
    {
        count: '04',
        image: require('./../../images/ui-10/services/4.jpg'),
        flaticon: require('./../../images/ui-10/icons/4.png'),
        title: 'Transport & Heavy Equipments',
        description: 'Discover our Transport & Heavy Equipment services designed to streamline your construction operations. Whether its material transport or robust machinery needs, we provide tailored solutions to meet your requirements. With a meticulously maintained fleet and experienced operators, we guarantee reliability, efficiency, and safety on-site. Choose us for dependable solutions that ensure your projects success.',
        redirect:'/heavy-equipment'
    },
    {
        count: '05',
        image: require('./../../images/ui-10/services/5.jpg'),
        flaticon: require('./../../images/ui-10/icons/5.png'),
        title: 'Fabrication & Erection',
        description: 'Experience unmatched precision and reliability with our customized Fabrication & Erection Services. Our team of skilled craftsmen, combined with state-of-the-art facilities, guarantees top-quality metalwork and steel structures. Whether its from conceptualization to completion, trust our expertise to deliver projects on time and within budget. Choose us for unparalleled craftsmanship and excellence in every aspect of fabrication and erection.',
        redirect:'/services-steel-structure'
    },
    {
        count: '06',
        image: require('./../../images/ui-10/services/6.jpg'),
        flaticon: require('./../../images/ui-10/icons/6.png'),
        title: 'M E P Services',
        description: 'Elevate your construction projects with our MEP (Mechanical, Electrical, and Plumbing) Services, optimizing functionality, efficiency, and sustainability. From initial design to ongoing maintenance, we seamlessly integrate these vital systems. Partner with us for dependable solutions that take your endeavors to new heights, ensuring smooth operations and lasting success.',
        redirect:'/mep'
    },
    {
        count: '07',
        image: require('./../../images/ui-10/services/7.jpg'),
        flaticon: require('./../../images/ui-10/icons/7.png'),
        title: 'Civil Defense Services',
        description: 'Safeguard your projects with our Fire fighting & fire alarm services, placing paramount importance on compliance and emergency readiness. Rely on our dependable solutions to ensure peace of mind, encompassing everything from fire protection measures to meticulous evacuation planning. Trust us to provide effective emergency response strategies tailored to your construction sites specific needs, ensuring the safety and security of all stakeholders involved.',
        redirect:'/services-civil'
    },
    {
        count: '08',
        image: require('./../../images/ui-10/services/8.jpg'),
        flaticon: require('./../../images/ui-10/icons/8.png'),
        title: 'Fit-Out Services',
        description: 'Experience our Fit-Out Services, where functionality meets brand identity. Collaborate with us for creativity, craftsmanship, and timely delivery. From offices to retail spaces, our team ensures every detail aligns with your vision. Creativity drives our approach, delivering innovative solutions. Skilled craftsmen guarantee precision and quality. Timely delivery is guaranteed, with proactive communication. Partner with us to bring your vision to life.',
        redirect:'/services-civil'
    },
    {
        count: '09',
        image: require('./../../images/ui-10/services/9.jpg'),
        flaticon: require('./../../images/ui-10/icons/9.png'),
        title: 'Engineering Survey',
        description: 'Engineering surveys are crucial for construction projects, enabling precise planning, risk management, and resource optimization. They provide accurate measurements and mapping of the site, identifying hazards such as unstable terrain for proactive risk mitigation. Additionally, they ensure regulatory compliance and optimize resource efficiency. Overall, engineering surveys are essential for delivering projects on time, within budget.',
        redirect:'/services-civil'
    }
]

class Services10 extends React.Component {
    
    render() {

        //item.count === pageName ? 'active' : ""
        
        return (
            <>
            <div className="section-full p-t80 p-b50 bg-white custom-bg-new">
            <div className="overlay-main site-bg-secondry opacity-07"></div>
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer text-center">
                            <div className="wt-small-separator site-text-primary custom-bg-check">
                                <div className="sep-leaf-left" />
                                <div>The Best Construction Industry Services</div>
                                <div className="sep-leaf-right" />
                            </div>
                            <h2 style={{ color: "#fff" }}>High Performance Services For Multiple Industries!</h2>
                        </div>
                    </div>
                </div>
        
                <div className="section-full p-t80 p-b50 bg-white">
                    <div className="container">
                        {/* TITLE START*/}
                        
                        {/* TITLE END*/}
                        <div className="section-content">
                            <div className="row justify-content-center">
                                {services.map((item, index) => (
                                    <div key={index} className="col-lg-4 col-md-8 m-b30 animate_line active">
                                    <div className="wt-box service-box-1-new bg-white cardbg">
                                        <div className="wt-icon-box-wraper-new">
                                            <div className="icon-xl inline-icon">
                                                <span className="icon-cell site-text-primary">
                                                <img src={item.flaticon} />
                                                </span>
                                            </div>
                                            <div className="wt-icon-number-new"><span>{item.count}</span></div>
                                        </div>
                                        <div className="service-box-title-new title-style-2 site-text-secondry">
                                            <h3 className="s-title-one">{item.title}</h3>
                                        </div>
                                        <div className="service-box-content-new">
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="service-box-new-link">
                                            <NavLink to={item.redirect} className="site-button-link">Read More</NavLink>
                                        </div>
                                        <div className="cardbg-pic" style={{ backgroundImage: "url(" + item.image + ")" }} />
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services10;