import React from 'react';
import { NavLink } from 'react-router-dom';

class About7 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-no-repeat bg-center bg-white">
                    <div className="about-section-three">
                        <div className="container">
                            <div className="section-content">
                                <div className="row justify-content-center d-flex">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="about-max-three">
                                            <div className="about-max-three-media"><img src={require('./../../images/ui-10/intro-pic.webp')} alt=""/></div>
                                            <div className="about-three">
                                                <div className="about-year">
                                                    <div className="about-year-info">
                                                        <span className="icon-md">
                                                            <i className="flaticon-industry" />
                                                        </span>
                                                        <h3 className="wt-tilte">Delivering Excellence</h3>
                                                        <h3 className="wt-tilte">Since 2009</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="about-section-three-right">
                                            {/* TITLE START*/}
                                            <div className="left wt-small-separator-outer">
                                                <div className="wt-small-separator site-text-primary">
                                                    <div className="sep-leaf-left" />
                                                    <div>Unveiling Skyland</div>
                                                    <div className="sep-leaf-right" />
                                                </div>
                                                <h2>Elevating Construction Excellence</h2>
                                            </div>
                                            {/* TITLE END*/}
                                            {/* Accordian */}
                                            <div className="wt-accordion acc-bg-gray m-b50 about-section-three-acc" id="accordion">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives" id="headingOne">
                                                        <h5 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                Holistic Expertise
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        From design to completion, our holistic expertise ensures every aspect of your project is handled with precision and care.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingTwo">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Client-Centric Philosophy
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Our client-centric philosophy ensures your vision is our priority, guiding every decision and action to exceed your expectations.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingThree">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Comprehensive Services
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Discover our full suite of services, spanning design, planning, execution, and project management for all your construction needs.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingFour">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                Safety Assurance
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Prioritizing safety, our trained team and rigorous protocols ensure a secure environment on every project we undertake.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingFive">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                Personalized Attention
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Tailored to you: From inception to completion, our personalized approach ensures your project reflects your vision and aspirations.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingSix">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                Buildign Relationships
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                            Building lasting relationships is our priority. We foster trust and collaboration with every client, supplier, and partner we work with.
                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="ab-three-info d-flex justify-content-between">
                                                <div className="welcom-btn-position m-t20"><NavLink to={"/about-1"} className="site-button site-btn-effect">More About</NavLink></div>
                                                <img src={require('./../../images/ui-10/sign.png')} alt="" />
                                            </div> */}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About7;