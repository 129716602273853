import React, {useCallback, useState} from 'react';
import ServicesSidebar from './../Segments/ServicesSidebar';

class ServiceDetails6 extends React.Component {

    render() {

        // Animate on click button and revert after 3000ms.

        return (
            <>

                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Engineering, Procurement &amp; Construction</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>Streamline Your Project with Expert EPC Services! Our integrated Engineering, Procurement, and Construction (EPC) approach ensures your project is delivered efficiently, combining innovative design, strategic material sourcing, and skilled construction. Experience timely, cost-effective, and high-quality project completion with our professional expertise. Lets build your vision into reality, creating lasting value together.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic1.webp')} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>In the competitive world of construction, success hinges on the seamless integration of engineering, procurement, and construction processes. At Sky Land Construction, we combine technical expertise, strategic procurement and meticulous execution to deliver projects that inspire confidence and exceed expectations.</p>
                                                <blockquote className="m-a0 bg-gray p-a30 lineUp3">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>You can dream, create, design, and build the most wonderful place in the world. But it requires people to make the dream a reality.
                    </p>
                                                    {/* <div className="p-t15 text-uppercase">
                                                        <strong>Walt Disney</strong>
                                                        <span>Author</span>
                                                    </div> */}
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>Key Benefits</h2>
                                            {/* <p>Lorem ipsume. </p> */}
                                            <div className="row">
                                                <div className="col-md-6">

                                                        <ul className="list-angle-right">
                                                            <li className="lineUp">
                                                                Streamlined Communication 123
                                                            </li>
                                                            <li className="lineUp1">
                                                                Integrated Approach
                                                            </li>
                                                            <li className="lineUp2">
                                                                Cost Efficiency
                                                            </li>
                                                            <li className="lineUp3">
                                                                Time Savings
                                                            </li>
                                                            <li className="lineUp4">
                                                                Quality Assurance
                                                            </li>
                                                            <li className="lineUp5">
                                                                Client Satisfaction
                                                            </li>
                                                        </ul>



                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg')} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ServiceDetails6;