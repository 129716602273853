import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/banner/8.jpg');

class Home3 extends React.Component {

    constructor(props) {
        super(props);

        window.addEventListener('load', function(){




        });



    }

    render() {

        window.scrollTo(0, 0);

        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="FAQ'S" pagename="FAQ'S" bgimage={bnrimg} />
                    {/* INNER PAGE BANNER END */}
                    {/* FAQ SECTION START */}
                    <div className="section-full p-t80 p-b50 bg-white">
                        <div className="container">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center">
                                <div className="wt-small-separator site-text-primary">
                                    <div className="sep-leaf-left" />
                                    <div>Frequently Asked Questions?</div>
                                    <div className="sep-leaf-right" />
                                </div>
                                <h2>Find informative answers to all your questions about Industrial products below.</h2>
                            </div>
                            {/* TITLE END*/}
                            <div className="section-content">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-8 col-md-6 col-sm-12">
                                        {/* Accordian */}

                                        <div className="wt-accordion acc-bg-gray m-b50 about-section-three-acc" id="accordion">
                                            <div className="panel wt-panel">
                                                <div className="acod-head acc-actives" id="headingOne">
                                                    <h5 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            What types of construction services do you offer?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        Explore the variety of construction services we provide, tailored for residential, commercial, industrial, and infrastructure projects.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head" id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Can you provide examples of past projects you've completed?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        Take a look at our portfolio showcasing completed projects to witness the caliber of our work and draw inspiration for your own project.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head" id="headingThree">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            What is your process for project management and communication?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        Our project management process ensures seamless coordination, clear communication, and timely execution. We prioritize open dialogue with clients, provide regular updates, and collaborate closely with stakeholders for successful outcomes.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head" id="headingFour">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            How do you handle changes or revisions to the project scope?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        We're committed to effectively accommodating changes during construction, ensuring minimal disruptions to timelines and budgets. Our flexible team swiftly addresses revisions to the project scope, providing transparent cost estimates and prioritizing client interests.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head" id="headingFive">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            How do you ensure quality and safety on the construction site?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        Quality and safety are our top concerns on every construction site. We adhere to strict quality control standards and prioritize safety through robust protocols and ongoing training. Our commitment ensures a secure environment for everyone involved.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head" id="headingSix">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            Can you provide references or testimonials from past clients?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        Absolutely, we're happy to provide references or testimonials from past clients who've experienced our quality work and professional team firsthand. These serve as a testament to our commitment to client satisfaction and exceeding expectations. Feel free to explore and hear directly from our satisfied clients about their experiences with us.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head" id="headingSeven">
                                                    <h5 className="mb-0">
                                                        <a className="collapsed" data-toggle="collapse" href="#collheadingSeven" aria-expanded="false" aria-controls="collheadingSeven">
                                                            What is your policy on warranties and guarantees for your work?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collheadingSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                                    <div className="acod-content p-tb15">
                                                        Our warranties and guarantees on workmanship and materials provide added confidence and peace of mind to our clients. We swiftly address any issues covered by these warranties, ensuring satisfaction and trust in our work.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="faq-sidebar">
                                            <div className="service-side-btn m-b30 site-bg-secondry p-a20 text-white">
                                                <h2 className="wt-title">Company Profile</h2>
                                                <p> Refer to our company profile for easy-to-read overview of all the services we offer.</p>
                                                <div className="wt-icon-box-wraper left ">
                                                    <NavLink target="_blank" to={"https://drive.google.com/file/d/1lIJDVViiVXDW0Q546tO64azTNKFf9SzB/view"} className="btn-block  p-a15 m-tb10">
                                                        <span className="text-black m-r10"><i className="fa fa-file-pdf-o" /></span>
                                                        <strong className="text-uppercase text-black">View PDF</strong>
                                                    </NavLink>
                                                </div>
                                                {/* <div className="wt-icon-box-wraper left">
                                                    <NavLink to={"#"} className="btn-block  p-a15 m-tb10">
                                                        <span className="text-black m-r10"><i className="fa fa-file-word-o" /></span>
                                                        <strong className="text-uppercase text-black"> Download .DOC</strong>
                                                    </NavLink>
                                                </div> */}
                                                {/* <div className="wt-icon-box-wraper left">
                                                    <NavLink to={"#"} className="btn-block p-a15 m-t10">
                                                        <span className="text-black m-r10"><i className="fa fa-file-powerpoint-o" /></span>
                                                        <strong className="text-uppercase text-black">Download .PPT</strong>
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                            <div className="wt-box p-a30 need-help site-bg-primary text-white m-b30">
                                                <h2 className="m-t0">Need any help!</h2>
                                                <p>Find answers to frequently asked questions about new developments, construction information and manymore. </p>
                                                <NavLink to={"/contactus"} className="site-button-secondry site-btn-effect">Contact Us</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ SECTION END */}
                </div>

                <Footer />

            </>
        );
    };
};

export default Home3;