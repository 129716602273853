import React from 'react';
import ServicesSidebar from './../Segments/ServicesSidebar';

class ServiceDetails7 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Engineering Survey</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>Before starting new developments or renovations, our team conducts thorough surveys to assess topography, geology, and infrastructure. These surveys provide essential data for precise planning and execution. Using advanced equipment and techniques, our skilled engineers map the terrain, identifying challenges and opportunities for optimal project design. With our expertise, Skyland Construction ensures meticulous planning and execution, delivering superior results to clients.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic10.jpg')} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>We offer precise and dependable dimensional data services spanning the entire construction journey. Our offerings encompass everything from initial topographical surveys and ground modeling to cut and fill computations, dimensional oversight, on-site layout, and final as-built drawings. Additionally, our expertise extends to specialized engineering surveys, including railway projects, coastal erosion studies, tunneling endeavors, and tasks on restricted access sites.</p>
                                                <p>
                                                <blockquote className="m-a0 bg-gray p-a30 lineUp">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>Precision in surveying is not just a goal; it's the standard by which engineering greatness is measured</p>
                                                    <div className="p-t15 text-uppercase">
                                                        {/* <strong>William Jomurray</strong>
                                                        <span>Envato Author</span> */}
                                                    </div>
                                                </blockquote>
                                                </p>
                                            </div>
                                
                                        </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>key Benefits</h2>
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> */}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li className="lineUp1">Digital terrain Modeling</li>
                                                        <li className="lineUp2">Land & Leveling Survey</li>
                                                        <li className="lineUp3">Topographical Survey</li>
                                                        <li className="lineUp4">U/G Utilities Survey</li>
                                                        <li className="lineUp5">Demarcations</li>
                                                        <li className="lineUp6">GIS Data collection services</li>
                                                        <li className="lineUp6">As Built Survey</li>
                                                        <li className="lineUp6">Contour Mapping</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg')} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ServiceDetails7;