import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import {click} from "@testing-library/user-event/dist/click";

const filters = [
    { label: "Industrial", filter: ".cat-1" },
    { label: "Residential Villas", filter: ".cat-2" },
    { label: "Infrastructure", filter: ".cat-3" },
    { label: "Fit-Outs", filter: ".cat-4" },
    { label: "Engineering Survey", filter: ".cat-5" }
];

const projects = [
    {
        image: require('./../../images/gallery/thumb/Industrial/kings/1.webp'),
        title: 'Kings Tobacco FZ LLC',
        description: 'Proposed Production Shed, Labour Accommodation, Utilities & Boundary wall!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/kings/2.webp'),
            require('./../../images/gallery/thumb/Industrial/kings/3.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/Ammetal/1.jpg'),
        title: 'Ammetal Kawasaki RAK LLC',
        description: 'Production Shed, Labour Accommodation, & Service Block!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/Ammetal/2.jpg'),
            require('./../../images/gallery/thumb/Industrial/Ammetal/3.jpg'),
           
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/Sarsan/1.webp'),
        title: 'Sarsan Heavy Engineering FZE',
        description: 'Production Shed, Office block!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/Sarsan/2.webp'),
            require('./../../images/gallery/thumb/Industrial/Sarsan/3.webp'),
            require('./../../images/gallery/thumb/Industrial/Sarsan/4.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/MC9/1.jpg'),
        title: 'RAK CERAMICS',
        description: 'MC-9 Production Factory Shed!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/MC9/2.jpeg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/Oceanoil/1.webp'),
        title: 'Ocean Oil Field Drilling',
        description: 'Proposed Factory Shed!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/Oceanoil/2.webp'),
        ]
    },  
    {
        image: require('./../../images/gallery/thumb/Industrial/sama/1.jpg'),
        title: 'Sama Petroleum Energy FZC',
        description: 'Committed to delivering high quality projects and innovate business!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/sama/2.jpg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/Spec/1.webp'),
        title: 'Spec International FZC!',
        description: 'Proposed Factory Shed!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/Spec/2.jpg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/transasia/1.webp'),
        title: 'Trans Asia Pipeline',
        description: 'Proposed factory shed & Office building!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/transasia/2.jpg'),
            require('./../../images/gallery/thumb/Industrial/transasia/3.jpg')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Industrial/Hadeedsteel/1.webp'),
        title: 'Hadeed Steel Industries!',
        description: 'Proposed factory shed & Office building!',
        filter: 'cat-1',
        images : [
            require('./../../images/gallery/thumb/Industrial/Hadeedsteel/2.webp'),
            require('./../../images/gallery/thumb/Industrial/Hadeedsteel/3.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/Ahmed Omer Ahmed/1.webp'),
        title: 'Ahmed Omer Ahmed',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/Ahmed Omer Ahmed/2.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Ahmed Omer Ahmed/3.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Ahmed Omer Ahmed/4.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/AhmedShaheenAlTaweel/1.webp'),
        title: 'Ahmed Shaheen Al Taweel',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/AhmedShaheenAlTaweel/1.webp'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/Ali Saeed Ahmed/1.webp'),
        title: 'Ali Saeed Ahmed',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/Ali Saeed Ahmed/2.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Ali Saeed Ahmed/3.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/Fareed Jumma Maftah/1.webp'),
        title: 'Ali Saeed Ahmed',
        description: 'Proposed Single Story Villa!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/Fareed Jumma Maftah/1.webp'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/Ibrahim Omer Ahmed/1.webp'),
        title: 'Ibrahim Omer Ahmed',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/Ibrahim Omer Ahmed/2.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Ibrahim Omer Ahmed/3.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/Mohammed Ali Mohammed/1.webp'),
        title: 'Mohammed Ali Mohammed',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/Mohammed Ali Mohammed/2.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Mohammed Ali Mohammed/3.webp')
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/Rashed Mohamed Ahmed/1.webp'),
        title: 'Rashed Mohamed Ahmed',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/Rashed Mohamed Ahmed/2.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Rashed Mohamed Ahmed/3.webp'),
            require('./../../images/gallery/thumb/Residentialvillas/Rashed Mohamed Ahmed/4.webp'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/ahmedibrahim/1.webp'),
        title: 'Ahmed Ibrahim',
        description: 'Proposed Single Story Villa!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/ahmedibrahim/2.jpg'),
            require('./../../images/gallery/thumb/Residentialvillas/ahmedibrahim/3.jpg'),
            require('./../../images/gallery/thumb/Residentialvillas/ahmedibrahim/4.webp'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Residentialvillas/soud/1.webp'),
        title: 'Saud Rashid Mohammed',
        description: 'Proposed Double Sotry Villa & Boundary wall!',
        filter: 'cat-2',
        images : [
            require('./../../images/gallery/thumb/Residentialvillas/soud/2.webp'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Infrastructure/198E/1.jpg'),
        title: 'Project: FEWA - 198E/2021',
        description: 'Access Road Construction & Rectification for OHL!',
        filter: 'cat-3',
        images : [
            require('./../../images/gallery/thumb/Infrastructure/198E/2.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/198E/3.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/198E/4.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/198E/5.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/198E/6.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/198E/7.jpg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Infrastructure/159E/1.jpg'),
        title: 'Project: FEWA - 159E/2019',
        description: '132KV U/G Cable Circuits (New Sharisha SS - Nakheel Main SS)!',
        filter: 'cat-3',
        images : [
            require('./../../images/gallery/thumb/Infrastructure/159E/2.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/3.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/4.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/5.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/6.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/7.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/8.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/9.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/10.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/11.jpg'),
            require('./../../images/gallery/thumb/Infrastructure/159E/12.jpg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Infrastructure/152E/1.webp'),
        title: 'Project: FEWA - 152E/2019',
        description: 'Access Road Construction & Rectification for OHL!',
        filter: 'cat-3',
        images : [
            require('./../../images/gallery/thumb/Infrastructure/152E/2.webp'),
            require('./../../images/gallery/thumb/Infrastructure/152E/3.webp'),
            require('./../../images/gallery/thumb/Infrastructure/152E/4.webp'),
            require('./../../images/gallery/thumb/Infrastructure/152E/5.webp'),
            require('./../../images/gallery/thumb/Infrastructure/152E/6.webp'),
            require('./../../images/gallery/thumb/Infrastructure/152E/7.webp'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Fit-Outs/Hot Bread Bakery/1.jpg'),
        title: 'Hot Bread Bakery',
        description: 'Interior Fitout Works!',
        filter: 'cat-4',
        images : [
            require('./../../images/gallery/thumb/Fit-Outs/Hot Bread Bakery/2.jpg'),
            require('./../../images/gallery/thumb/Fit-Outs/Hot Bread Bakery/3.jpg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Fit-Outs/oromos/1.jpg'),
        title: 'Oromos Speciality Coffee',
        description: 'Interior Fitout Works!',
        filter: 'cat-4',
        images : [
            require('./../../images/gallery/thumb/Fit-Outs/oromos/2.jpg'),
            require('./../../images/gallery/thumb/Fit-Outs/oromos/3.jpg'),
            require('./../../images/gallery/thumb/Fit-Outs/oromos/4.jpg'),
        ]
    },
    {
        image: require('./../../images/gallery/thumb/Engineering Survey/1.jpg'),
        title: 'Land Surveying',
        description: 'Providing all kind of Enginnering Surveys!',
        filter: 'cat-5',
        images : [
            require('./../../images/gallery/thumb/Engineering Survey/2.jpg'),
            require('./../../images/gallery/thumb/Engineering Survey/3.jpg'),
            require('./../../images/gallery/thumb/Engineering Survey/4.jpg'),
        ]
    },
];

class Projects13 extends React.Component {

    first = true;
    constructor(props) {
        super(props);


    }

    render() {

        //document.getElementById('clickme').click();

        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white">
                    <div className="container">
                        {/* PAGINATION START */}
                        <div className="filter-wrap work-grid p-b30 text-center">
                            <ul className="masonry-filter link-style ">
                                <li className="active hide-force"><NavLink to={"#"} data-filter="*">All Cases</NavLink></li>
                                {filters.map((item, index) => (
                                    <li className={index == 0 ? 'active clickme' : ''} key={index}><NavLink to={"#"} id={index == 0 ? 'clickme' : ''} data-filter={item.filter}>{item.label}</NavLink></li>
                                ))}
                            </ul>
                        </div>
                        {/* PAGINATION END */}
                        {/* GALLERY CONTENT START */}
                        <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 row">
                        {projects.map((item, index) => (
                                <div className="masonry-wrap mfp-gallery justify-content-center flex-wrap col-lg-4 col-md-4 col-sm-4 col-md-4">
                                    <div key={index} data-id={index} className={`${item.filter} mfp-gallery`+index+` project-items masonry-item m-b30 `} >
                                        <div className="project-img-effect-1">
                                                <div className="imageHolder"  style={{
                                                    backgroundImage: "url(" + item.image + ")",
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat'
                                                }}>
                                                </div>
                                            <div className="wt-info">
                                                <h3 className="wt-tilte m-b10 m-t0">{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                            <a href={item.image}  className={"mfp-link mfp-link"+index}><i className="fa fa-search-plus" /></a>

                                        </div>
                                        {item.images.map((item1, index1) => (
                                            <a key={index1} href={item1} className={"hideimage mfp-link"+index}></a>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                </div>
            </>
        );
    }
};

export default Projects13;