import React from 'react';
import { NavLink } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

var bnr1 = require('./../../images/background/bg-5.png');

class Video2 extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <>
                <div className="section-full  p-t80 p-b50 bg-white  bg-bottom-right bg-no-repeat" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-12 col-md-12 m-b30">

                                <div className=" wt-small-separator-outer left">
                                    <div className="wt-small-separator site-text-primary ">
                                        <div className="sep-leaf-left" />
                                        <div>Vision &amp; Mission Statement</div>
                                        <div className="sep-leaf-right" />
                                    </div>
                                 </div>
                                
                            </div>

                            <div className="col-lg-12 col-md-12 row ">

                                <div className="col-lg-6 col-md-6 row">
                                    <h2 className="font-45">Building a brighter future with dedication and creativity, making every achievement a step towards a better world!</h2>
                                    <div className="video-section2-outer mfp-gallery">
                                        <div className="video-section">
                                            <NavLink to={"#"} className="play-now" onClick={this.openModal}>
                                                <i className="icon fa fa-play" />
                                                <span className="ripple" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1 center">

                                </div>

                                <div className="col-lg-5 col-md-5">
                                    <h2 className="">Vision</h2>
                                    <p className="font-14">To be the construction industry's most preferred and trusted partner, transforming the landscape through innovative design, sustainable practices, and excellence in execution. We envision a future where our projects not only meet but exceed the evolving needs of our communities, setting new standards for quality, safety, and environmental stewardship .</p>

                                    <h2 className="">Mission</h2>
                                    <p className="font-14">Our mission is to deliver exceptional construction services with a focus on quality, integrity, and sustainability. We are committed to building lasting relationships with our clients by understanding their needs and exceeding their expectations. Through the dedication of our skilled team, state-of-the-art technology, and a relentless pursuit of excellence, we aim to contribute positively to our communities and create enduring value for all stakeholders.</p>

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bDCgqnpRQDo' onClose={() => this.setState({ isOpen: false })} />
            </>
        );
    }
};

export default Video2;