// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author    ThemePunch <info@themepunch.com>
 * @link      http://www.themepunch.com/
 * @copyright 2017 ThemePunch
 * @version   1.0.0
 */

.rs_addon_revealer {
	
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
	
}

.rsaddon-revealer-overlay {
	
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	
}

.rs_addon_revealer div,
.rs_addon_revealer svg {
	
	position: absolute;
	
}

.rs_addon_reveal .tp-loader {
	
	visibility: visible !important;
	display: inline-block !important;
	pointer-events: none;
	
}

.rs_addon_revealer svg {
	
	width: 100%;
	height: 100%;
	
}

.rs_addon_revealer_special .tp-revslider-mainul {
	
	opacity: 0 !important;
	
}




`, "",{"version":3,"sources":["webpack://./src/assets/plugins/revolution/revolution-addons/reveal/css/revolution.addon.revealer.css"],"names":[],"mappings":"AAAA;;;;;EAKE;;AAEF;;CAEC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,aAAa;CACb,gBAAgB;;AAEjB;;AAEA;;CAEC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,MAAM;CACN,OAAO;;AAER;;AAEA;;;CAGC,kBAAkB;;AAEnB;;AAEA;;CAEC,8BAA8B;CAC9B,gCAAgC;CAChC,oBAAoB;;AAErB;;AAEA;;CAEC,WAAW;CACX,YAAY;;AAEb;;AAEA;;CAEC,qBAAqB;;AAEtB","sourcesContent":["/**\n * @author    ThemePunch <info@themepunch.com>\n * @link      http://www.themepunch.com/\n * @copyright 2017 ThemePunch\n * @version   1.0.0\n */\n\n.rs_addon_revealer {\n\t\n\twidth: 100%;\n\theight: 100%;\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tz-index: 9999;\n\toverflow: hidden;\n\t\n}\n\n.rsaddon-revealer-overlay {\n\t\n\twidth: 100%;\n\theight: 100%;\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\t\n}\n\n.rs_addon_revealer div,\n.rs_addon_revealer svg {\n\t\n\tposition: absolute;\n\t\n}\n\n.rs_addon_reveal .tp-loader {\n\t\n\tvisibility: visible !important;\n\tdisplay: inline-block !important;\n\tpointer-events: none;\n\t\n}\n\n.rs_addon_revealer svg {\n\t\n\twidth: 100%;\n\theight: 100%;\n\t\n}\n\n.rs_addon_revealer_special .tp-revslider-mainul {\n\t\n\topacity: 0 !important;\n\t\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
