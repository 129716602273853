import React from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

var bnr1 = require('./../../images/background/bg-2.jpg');

class Statistics11 extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <>
                <div className="section-full video2-counter-section p-t80 p-b40  overlay-wraper bg-cover bg-no-repeat" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <div className="overlay-main site-bg-secondry opacity-09" />
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer text-center custom-new-white">
                            <div className="wt-small-separator site-text-primary">
                                <div className="sep-leaf-left" />
                                <div>All Solutions</div>
                                <div className="sep-leaf-right" />
                            </div>
                            <h2 style={{ color: "#fff" }}>Obtain Solutions for Every Construction Sector</h2>
                        </div>
                        {/* TITLE END*/}
                        <div className="counter2-section-outer-top">
                            <div className="counter-outer">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-md-6 m-b30 ">
                                        <div className="wt-icon-box-wraper center">
                                            <h2 className="counter site-text-primary"><CountUp end={50} duration={10} /></h2>
                                            <span className="site-text-white title-style-2">Projects Successfully Delivered</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 m-b30">
                                        <div className="wt-icon-box-wraper center">
                                            <h2 className="counter site-text-primary"><CountUp end={110} duration={10} /></h2>
                                            <span className="site-text-white title-style-2">Occupational Status</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 m-b30">
                                        <div className="wt-icon-box-wraper center">
                                            <h2 className="counter site-text-primary"><CountUp end={15} duration={10} /></h2>
                                            <span className="site-text-white title-style-2">Commencing from the years</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-3 col-md-6 m-b30">
                                        <div className="wt-icon-box-wraper center">
                                            <h2 className="counter site-text-primary"><CountUp end={26} duration={10} /></h2>
                                            <span className="site-text-white title-style-2">Winning Awards</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="counter2-section-outer-bottom">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 m-b30 ">
                                    <div className="video-section2-outer mfp-gallery">
                                        <div className="video-section">
                                            <NavLink to={"#"} className="play-now" onClick={this.openModal}>
                                                <i className="icon fa fa-play" />
                                                <span className="ripple" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-6 m-b30">
                                    <div className="video-section-blockquote">
                                        <i className="fa fa-quote-left" />
                                        <span className="q-author-detail site-text-white title-style-2">Make it a habit to go above and beyond, delivering significantly more than what is expected every time.</span>
                                        <div className="q-author-name site-text-primary title-style-2">Babar Mohammed, CEO</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='6oX0UdJ66VU' onClose={() => this.setState({ isOpen: false })} />
            </>
        );
    }
};

export default Statistics11;