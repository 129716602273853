import React from 'react';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';
import About2 from './../Segments/About2';
import Services10 from './../Segments/Services10';
import Statistics11 from './../Segments/Statistics11';
import Video2 from './../Segments/Video2';
import { useParams } from 'react-router-dom';

import ClientsLogo1 from './../Segments/ClientsLogo1';
import ClientsLogo10 from "../Segments/ClientsLogo10";
var bnrimg = require('./../../images/banner/3.jpg');

class About1 extends React.Component {
    render() {

        window.scrollTo(0, 0);

        const searchData = window.location.search;
        const param      = new URLSearchParams(searchData);

        return (
            <>
                <Header />
                <Banner title="About Us" pagename="About 1" bgimage={bnrimg}/>
                <About2 />
                <Services10 pagename={param.get('section')} />
                <Statistics11 />
                <Video2 />
                <ClientsLogo10 />
                <Footer />
            </>
        );
    };
};

export default About1;