import React from 'react';
import { NavLink } from 'react-router-dom';

class About1 extends React.Component {
    render() {
        
        return (
            <>
                <div className="section-full welcome-section-outer">
                    <div className="welcome-section-top bg-white p-tb80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="img-colarge">
                                        <div className="colarge-1"><img src={require('./../../images/colarge/s1.webp')} alt="" className="slide-righ" /></div>
                                        <div className="since-year-outer"><div className="since-year"><span>Since</span><strong>2009</strong></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="welcom-to-section">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Welcome to Sky Land</div>
                                                {/* <div className="sep-leaf-right" /> */}
                                            </div>
                                        </div>
                                        <h2>We Are Here to Increase Your Knowledge With Experience</h2>
                                        {/* TITLE END*/}
                                        <ul className="site-list-style-one">
                                            <li>Quality Control System , 100% Satisfaction Guarantee</li>
                                            <li>Unrivalled Workmanship, Professional and Qualified</li>
                                            <li>Environmental Sensitivity, Personalised Solutions</li>
                                        </ul>
                                        <p>Our philosophy extends beyond physical construction; we prioritize building enduring relationships and nurturing communities. Driven by a commitment to excellence and guided by integrity, our goal is to exceed expectations with each project we undertake, always striving to go above and beyond in delivering exceptional results and making a positive impact that resonates within the communities we serve. Through this dedication, we aim to create a legacy of trust, reliability, and lasting value.</p>
                                        <div className="welcom-to-section-bottom d-flex justify-content-between">
                                            <div className="welcom-btn-position"><NavLink to={"/about-1"} className="site-button-secondry site-btn-effect">More About</NavLink></div>
                                            <div className="welcom-sign-pic"><img className="adjust-sign" src={require('./../../images/sign.webp')} alt="" /></div>
                                            <div className="welcom-sign-info"><strong>Babar Mohammed</strong><span>( CEO &amp; Founder )</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About1;