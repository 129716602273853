// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tp-caption[data-typewriter]:after {
	
	position: absolute;
	
}

.tp-caption[data-typewriter-blinking]:after {
	
	position: static;
	
}

@keyframes tp-typewriter {
	
	from {visibility: visible}
	to {visibility: hidden}
	
}`, "",{"version":3,"sources":["webpack://./src/assets/plugins/revolution/revolution-addons/typewriter/css/typewriter.css"],"names":[],"mappings":"AAAA;;CAEC,kBAAkB;;AAEnB;;AAEA;;CAEC,gBAAgB;;AAEjB;;AAEA;;CAEC,MAAM,mBAAmB;CACzB,IAAI,kBAAkB;;AAEvB","sourcesContent":[".tp-caption[data-typewriter]:after {\n\t\n\tposition: absolute;\n\t\n}\n\n.tp-caption[data-typewriter-blinking]:after {\n\t\n\tposition: static;\n\t\n}\n\n@keyframes tp-typewriter {\n\t\n\tfrom {visibility: visible}\n\tto {visibility: hidden}\n\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
